button,
input[type="button"],
input[type="reset"],
input[type="submit"],
.btn,
.nav-links a {
	border: 1px solid;
	border-color: $color__border-button;
	border-radius: 0;
	background: $color__background-button;
	color: $color__primary-1;
	line-height: 1;
	padding: 0.5em 1.2em; // Not default - change when styling other forms *
	text-transform: lowercase;
	@include font-size(1.683);
	font-weight: 300;
	transition: all .2s ease;

	&:visited {
		color: $color__primary-1;
	}

	&.light {
		border-color: white;
		color: white;

		&:visited {
			color: white;
		}
	}

	&:hover,
	&:active,
	&:focus {
		border-color: $color__border-button-hover;
		background-color: $color__secondary-6 !important;
		border: 1px solid $color__secondary-6;
		color: white;
		text-decoration: none;
	}

	@media screen and (max-width: 1024px) {
		@include font-size(1.383);
	}

	@media screen and (max-width: 767px) {
		@include font-size(1);
	}
}

#header-search {
	button,
	input[type="button"],
	input[type="reset"],
	input[type="submit"],
	.btn {
		padding: 0.1em 0.6em;
		@include font-size(1.5);
	}
}

.section.contact-form.bg-img {
	.c-forms-form {
		button {
			@extend button, .light;
			@include font-size(1.875);
			padding: 0.5rem 5.625rem;

			&:hover, &:active, &:focus {
				border: 1px solid $color__secondary-6;
			}
		}
	}
}

.c-button-section {
  display: flex;
  justify-content: center;
}

a.sticky-side-button {
	position: fixed;
	bottom: 20%;
	right: -80px;
	background-color: $color__secondary-10;
	color: white;
	text-transform: uppercase;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	transform: rotateZ(-90deg);
	@include font-size(1.5);
	z-index: 999;
	font-family: arial;
	padding: 0.5em 1em;
	transition: all .2s ease;

	&:hover, &:focus, &:active, &:visited {
		color: white;
		text-decoration: none;
	}

	&:hover, &:focus, &:active {
		background-color: $color__secondary-9;
	}

	@media screen and (max-width: 992px) {
		transform: rotateZ(0);
		right: 0; bottom: 0; left: 0;
		text-align: center;
		border-radius: 0;
		@include font-size(1.2);
	}
}
