body {
	font-weight: 300;
}

p {
	margin-bottom: 1.5em;
}

dfn, cite, em, i {
	font-style: italic;
}

blockquote {
	margin: 0 1.5em;
}

address {
	margin: 0 0 1.5em;
}

pre {
	background: $color__background-pre;
	font-family: $font__pre;
	@include font-size(0.9375);
	line-height: $font__line-height-pre;
	margin-bottom: 1.6em;
	max-width: 100%;
	overflow: auto;
	padding: 1.6em;
}

code, kbd, tt, var {
	font-family: $font__code;
	@include font-size(0.9375);
}

abbr, acronym {
	border-bottom: 1px dotted $color__border-abbr;
	cursor: help;
}

mark, ins {
	background: $color__background-ins;
	text-decoration: none;
}

big {
	font-size: 125%;
}

 /* Tooltip container */
 [data-toggle="tooltip"] {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
  color: initial !important;

  .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
  
    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
  
    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;

    &:after { // Arrow
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #555 transparent transparent transparent;
    }
  }

  &:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
}
