// Rem output with px fallback
@mixin font-size($sizeValue: 1) {
	font-size: ($sizeValue * 16) * 1px;
	font-size: $sizeValue * 1rem;
}

// Center block
@mixin center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

// Clearfix
@mixin clearfix() {
	content: "";
	display: table;
	table-layout: fixed;
}

// Clear after (not all clearfix need this also)
@mixin clearfix-after() {
	clear: both;
}

// Column width with margin
@mixin column-width($numberColumns: 3) {
	width: map-get( $columns, $numberColumns ) - ( ( $columns__margin * ( $numberColumns - 1 ) ) / $numberColumns );
}

@mixin bg-props($bg-position: center, $bg-repeat: no-repeat, $bg-size: cover) {
	background-position: $bg-position;
	background-repeat: $bg-repeat;
	background-size: $bg-size;
}