.section.usp-row {
    font-weight: 500;
    text-align: center;

    p {
        text-align: center;
        margin: 1em auto 1.5em;
    }

    .usp-block {
        text-align: center;
        position: relative;
        width: 100%;
        padding: 4em 1.5em 3em;
        margin: 3em;
        box-shadow: -4px 4px 0px 0px rgba(13, 62, 70, 0.25);
        @include bg-props();

        .copy-wrap {
            position: relative;
            z-index: 1;
            @include font-size(1.2);
            line-height: 1.3;

            @media screen and (max-width: 767px) {
                @include font-size(1);
            }

            .usp-main {
                @include font-size(4.5);
                margin: 0;
                font-weight: 300;

                @media screen and (max-width: 767px) {
                    @include font-size(3.25);
                }
            }
        }

        img {
            object-fit: cover;
            object-position: center center;
            width: 100%;
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
            background-color: transparentize(white, 0.25);
        }

        @media screen and (max-width: 767px) {
            margin: 3em 0;
        }
    }
}