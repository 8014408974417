@keyframes arrow-width {
    0% {
        max-width: 0
    }

    to {
        max-width: 100%
    }
}

.container__pipeline {
    overflow: hidden;

    @media screen and (max-width: 767px) {
        overflow-x: scroll;
    }
}

.container__pipeline .pipeline-diagram {
    padding: 0
}

@media only screen and (max-width: 1023px) {
    .container__pipeline {
        width:100%;
        max-width: 100%
    }
}

.container__pipeline .html__content {
    margin-bottom: 50px
}

@media only screen and (min-width: 1024px) {
    .container__pipeline .html__content {
        padding:0
    }
}

.container__pipeline .pipeline__block {
    margin-bottom: 60px
}

@media only screen and (max-width: 1023px) {
    .container__pipeline .pipeline__block__title {
        margin-left:16px!important
    }
}

.container__pipeline .pipeline__block .cell-row-header {
    display: flex;
    border-bottom: 1px solid transparentize($color__primary-1, 0.5);
}

@media only screen and (max-width: 1023px) {
    .container__pipeline .pipeline__block .cell-row-header {
        justify-content: space-between;
        padding-right: 0;
        padding-bottom: 10px;
        padding-top: 10px
    }

    .container__pipeline .pipeline__block .cell-row-header .dummy-block-category {
        width: 70px;
        margin-right: 8px
    }
}

@media only screen and (max-width: 399px) {
    .container__pipeline .pipeline__block .cell-row-header .dummy-block-category {
        width:30px;
        margin-right: 8px
    }
}

.container__pipeline .pipeline__block .cell-row-header .dummy-block-text {
    text-align: center;
    font-size: 12px;
    line-height: normal;
}

@media only screen and (min-width: 1024px) {
    .container__pipeline .pipeline__block .cell-row-header .dummy-block-text {
        margin-bottom:16px
    }
}

@media only screen and (min-width: 320px) {
    .container__pipeline .pipeline__block .cell-row-header .dummy-block-text {
        font-size:calc(12px + (400vw - 1280px)/320)
    }
}

@media only screen and (min-width: 640px) {
    .container__pipeline .pipeline__block .cell-row-header .dummy-block-text {
        font-size:16px
    }
}

@media only screen and (min-width: 641px) {
    .container__pipeline .pipeline__block .cell-row-header .dummy-block-text {
        font-size:14px
    }
}

.container__pipeline .pipeline__block .cell-row-header .block-number {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

@media only screen and (max-width: 1023px) {
    .container__pipeline .pipeline__block .cell-row-header .block-number {
        line-height:16px;
    }
}

@media only screen and (max-width: 1023px) {
    .container__pipeline .pipeline__block .cell-row-header .block-number.block-number-1 {
        display:none
    }
}

@media only screen and (max-width: 1023px) {
    .container__pipeline .pipeline__block .cell-row-header .block-number.block-number-2,.container__pipeline .pipeline__block .cell-row-header .block-number.block-number-3,.container__pipeline .pipeline__block .cell-row-header .block-number.block-number-4,.container__pipeline .pipeline__block .cell-row-header .block-number.block-number-5 {
        width:25%;
        margin: 0 3px
    }
}

.container__pipeline .pipeline__block .cell-row-header.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 0 5px #ccc;
    z-index: 1;
    background: #fff
}

.container__pipeline .pipeline__block .cell-table {
    display: flex;

    &.table-header {
        .block__category {
            .text {
                color: $color__primary-1 !important;
            }
        }
    }
}

.container__pipeline .pipeline__block .cell-table .block__category {
    width: 10%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    min-width: 111px;
}

.container__pipeline .pipeline__block .table-header .cell-table .block__category {
    color: $color__primary-1;
}

.container__pipeline {
    .pipeline__block {
        .cell-table {
            &.color__secondary-1 {
                .block__category {
                    background: $color__secondary-1;
                }

                .cell-row-wrapper {
                    .block__category {
                        background: darken(saturate(adjust-hue($color__secondary-1, 4), 15.45), 12.75);
                    }

                    .arrow-percentage {
                        background: darken(saturate(adjust-hue($color__secondary-1, 4), 15.45), 12.75);

                        &:before {
                            border-left: 14px solid darken(saturate(adjust-hue($color__secondary-1, 4), 15.45), 12.75);
                        }
                    }
                }
            }
            &.color__secondary-2 {
                .block__category {
                    background: $color__secondary-2;
                }

                .cell-row-wrapper {
                    .block__category {
                        background: darken(saturate(adjust-hue($color__secondary-2, 4), 15.45), 12.75);
                    }

                    .arrow-percentage {
                        background: darken(saturate(adjust-hue($color__secondary-2, 4), 15.45), 12.75);

                        &:before {
                            border-left: 14px solid darken(saturate(adjust-hue($color__secondary-2, 4), 15.45), 12.75);
                        }
                    }
                }
            }
            &.color__secondary-3 {
                .block__category {
                    background: $color__secondary-3;
                }

                .cell-row-wrapper {
                    .block__category {
                        background: darken(desaturate(adjust-hue($color__secondary-3, 2), 4.66), 11.18);
                    }

                    .arrow-percentage {
                        background: darken(desaturate(adjust-hue($color__secondary-3, 2), 4.66), 11.18);

                        &:before {
                            border-left: 14px solid darken(desaturate(adjust-hue($color__secondary-3, 2), 4.66), 11.18);
                        }
                    }
                }
            }
            &.color__secondary-4 {
                .block__category {
                    background: $color__secondary-4;
                }

                .cell-row-wrapper {
                    .block__category {
                        background: darken(desaturate(adjust-hue($color__secondary-4, 1), 2.11), 7.06);
                    }

                    .arrow-percentage {
                         background: darken(desaturate(adjust-hue($color__secondary-4, 1), 2.11), 7.06);

                        &:before {
                            border-left: 14px solid darken(desaturate(adjust-hue($color__secondary-4, 1), 2.11), 7.06);
                        }
                    }
                }
            }
            &.color__secondary-5 {
                .block__category {
                    background: $color__secondary-5;
                }

                .cell-row-wrapper {
                    .block__category {
                        background: darken(saturate(adjust-hue($color__secondary-5, 4), 15.45), 12.75);
                    }

                    .arrow-percentage {
                        background: darken(saturate(adjust-hue($color__secondary-5, 4), 15.45), 12.75);

                       &:before {
                           border-left: 14px solid darken(saturate(adjust-hue($color__secondary-5, 4), 15.45), 12.75);
                       }
                    }
                }
            }
            &.color__secondary-6 {
                .block__category {
                    background: $color__secondary-6;
                }

                .cell-row-wrapper {
                    .block__category {
                        background: darken(saturate(adjust-hue($color__secondary-6, -7), 3.25), 4.51);
                    }

                    .arrow-percentage {
                        background: darken(saturate(adjust-hue($color__secondary-6, -7), 3.25), 4.51);

                       &:before {
                           border-left: 14px solid darken(saturate(adjust-hue($color__secondary-6, -7), 3.25), 4.51);
                       }
                    }
                }
            }
            &.color__secondary-7 {
                .block__category {
                    background: $color__secondary-7;
                }

                .cell-row-wrapper {
                    .block__category {
                        background: darken(saturate(adjust-hue($color__secondary-7, 4), 15.45), 12.75);
                    }

                    .arrow-percentage {
                        background: darken(saturate(adjust-hue($color__secondary-7, 4), 15.45), 12.75);

                       &:before {
                           border-left: 14px solid darken(saturate(adjust-hue($color__secondary-7, 4), 15.45), 12.75);
                       }
                    }
                }
            }
            &.color__secondary-8 {
                .block__category {
                    background: $color__secondary-8;
                }

                .cell-row-wrapper {
                    .block__category {
                        background: darken(saturate(adjust-hue($color__secondary-8, 4), 15.45), 12.75);
                    }

                    .arrow-percentage {
                        background: darken(saturate(adjust-hue($color__secondary-8, 4), 15.45), 12.75);

                       &:before {
                           border-left: 14px solid darken(saturate(adjust-hue($color__secondary-8, 4), 15.45), 12.75);
                       }
                    }
                }
            }
            &.color__secondary-9 {
                .block__category {
                    background: $color__secondary-9;
                }

                .cell-row-wrapper {
                    .block__category {
                        background: darken(saturate(adjust-hue($color__secondary-9, 4), 15.45), 12.75);
                    }

                    .arrow-percentage {
                        background: darken(saturate(adjust-hue($color__secondary-9, 4), 15.45), 12.75);

                       &:before {
                           border-left: 14px solid darken(saturate(adjust-hue($color__secondary-9, 4), 15.45), 12.75);
                       }
                    }
                }
            }
            &.color__secondary-10 {
                .block__category {
                    background: $color__secondary-10;
                }

                .cell-row-wrapper {
                    .block__category {
                        background: darken(saturate(adjust-hue($color__secondary-10, 4), 15.45), 12.75);
                    }

                    .arrow-percentage {
                        background: darken(saturate(adjust-hue($color__secondary-10, 4), 15.45), 12.75);

                       &:before {
                           border-left: 14px solid darken(saturate(adjust-hue($color__secondary-10, 4), 15.45), 12.75);
                       }
                    }
                }
            }
        }

        .block__category {
            &.partner {
                background: none !important;
                color: $color__primary-1;
                border-bottom: 1px solid transparentize($color__primary-1, 0.5);
                border-right: 1px solid transparentize($color__primary-1, 0.5);
                border-left: 1px solid transparentize($color__primary-1, 0.5);

                @media screen and (max-width: 1023px) {
                    border-top: 1px solid transparentize($color__primary-1, 0.5);
                    margin-top: -1px;
                }
            }
        }

        .phase-indicator {
            position: absolute;
            top: 50%;
            left: 5%;
            transform: translateY(-50%);
            color: white;
            display: none;

            @media screen and (max-width: 1023px) {
                display: block;
            }
        }
    }
}

.container__pipeline .pipeline__block .multi-rows-wrapper .cell-table .block__category {
    width: 15%;
    min-width: 40px;
}

@media only screen and (min-width: 768px) {
    .container__pipeline .pipeline__block .cell-table .block__category {
        margin-bottom:0
    }
}

.container__pipeline .pipeline__block .cell-table .block__category .text {
    width: 100%;
    padding: 0 10px;

}

.container__pipeline .pipeline__block .table-header .cell-table .block-category {
    color: $color__primary-1 !important;
}

@media only screen and (max-width: 1023px) {
    .container__pipeline .pipeline__block .cell-table .block__category {
        min-width:unset;
        align-items: flex-start
    }

    .container__pipeline .pipeline__block .cell-table .block__category .text {
        transform: rotate(90deg) translateX(50px);
        padding: 0;
        white-space: nowrap
    }
}

.container__pipeline .pipeline__block .multi-rows-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.container__pipeline .pipeline__block .indication-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;

    .data-column-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}

.container__pipeline .pipeline__block .cell-row-wrapper {
    width: 100%;
    flex-grow: 1;

    @media screen and (max-width: 1023px) {
        //border-top: 1px solid transparentize($color__primary-1, 0.5);
    }
}

.container__pipeline .pipeline__block .cell-row-wrapper.Grey .dropdown-row:hover {
    cursor: pointer
}

.container__pipeline .pipeline__block .cell-row-wrapper.Grey .dropdown-row:hover .cell,
.container__pipeline .pipeline__block .cell-row-wrapper.Grey .dropdown-row:hover .cell-empty-row-wrapper .cell {
    background: #cfcfcf!important
}

.container__pipeline .pipeline__block .cell-row {
    display: flex;
    margin-bottom: 0;
    position: relative;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.container__pipeline .pipeline__block .table-header {
    text-align: center;
    .cell-row {
        flex-wrap: nowrap;

        .cell {
            border-right: 0;
        }
        .cell-empty-row-wrapper {
            .cell-empty-col {
                border-right: 0;
            }
        }
    }

    .block__category.partner {
        border-left: 0;
        border-right: 0;
    }

    @media screen and (max-width: 1023px) {
        display: none;
    }
}

@media only screen and (max-width: 1023px) {
    .container__pipeline .pipeline__block .cell-row {
        margin-bottom:0
    }
}

.container__pipeline .pipeline__block .cell-row .dropdown-circle {
    width: 22.5px;
    height: 22px;
    background: #f16e30;
    border-radius: 50%;
    text-align: center;
    line-height: 22px;
    color: #fff;
    position: absolute;
    top: 12px;
    left: 9px;
    display: flex;
    align-items: center;
    justify-content: center
}

@media (device-width: 768px) and (device-height:1024px) and (orientation:portrait) {
    .container__pipeline .pipeline__block .cell-row .dropdown-circle {
        height:20px;
        width: 20px;
        line-height: 16px
    }
}

.container__pipeline .pipeline__block .cell-row .dropdown-circle .minus {
    display: none;
    padding-left: .5px;
}

@media (device-width: 768px) and (device-height:1024px) and (orientation:portrait) {
    .container__pipeline .pipeline__block .cell-row .dropdown-circle .minus {
        padding-right:.5px;
        padding-left: 0
    }
}

.container__pipeline .pipeline__block .cell-row .dropdown-circle .plus {
    padding-left: .5px;
}

@media (device-width: 768px) and (device-height:1024px) and (orientation:portrait) {
    .container__pipeline .pipeline__block .cell-row .dropdown-circle .plus {
        padding-right:.5px;
        padding-left: 0
    }
}

.container__pipeline .pipeline__block .cell-row.row-open .special-cell {
    display: block
}

.container__pipeline .pipeline__block .cell-row.row-open .special-cell .bullet {
    color: #f16e30
}

@media only screen and (max-width: 1023px) {
    .container__pipeline .pipeline__block .cell-row.row-open .special-cell {
        background:#e7f7f9;
        margin: 0 3px
    }
}

.container__pipeline .pipeline__block .cell-row.row-open .special-cell .close-dropdown {
    color: #f16e30;
    ;
    font-size: 14px
}

@media only screen and (min-width: 320px) {
    .container__pipeline .pipeline__block .cell-row.row-open .special-cell .close-dropdown {
        font-size:calc(14px + (800vw - 2560px)/320)
    }
}

@media only screen and (min-width: 640px) {
    .container__pipeline .pipeline__block .cell-row.row-open .special-cell .close-dropdown {
        font-size:22px
    }
}

@media only screen and (min-width: 641px) {
    .container__pipeline .pipeline__block .cell-row.row-open .special-cell .close-dropdown {
        font-size:16px
    }
}

.container__pipeline .pipeline__block .cell-row.row-open .special-cell a {
    text-decoration: none;

}

@media only screen and (max-width: 640px) {
    .container__pipeline .pipeline__block .cell-row.row-open .special-cell {
        padding:20px 30px 25px
    }
}

.container__pipeline .pipeline__block .cell-row.row-open .special-cell-mobile {
    display: none
}

@media only screen and (max-width: 1023px) {
    .container__pipeline .pipeline__block .cell-row.row-open .special-cell-mobile {
        display:block
    }

    .container__pipeline .pipeline__block .cell-row.row-open .special-cell-desktop {
        display: none
    }
}

.container__pipeline .pipeline__block .cell-row.row-open .dropdown-circle .plus {
    display: none;
    line-height: 22px;
    padding-left: .5px
}

.container__pipeline .pipeline__block .cell-row.row-open .dropdown-circle .minus {
    display: block;
    line-height: 22px;
    padding-left: .5px
}

.container__pipeline .pipeline__block .cell-row.dropdown-row:hover {
    cursor: pointer
}

.container__pipeline .pipeline__block .cell-row.dropdown-row:hover .cell,.container__pipeline .pipeline__block .cell-row.dropdown-row:hover .cell-empty-row-wrapper .cell {
    background: #bae5eb!important
}

.container__pipeline .pipeline__block .cell-row:last-child {
    margin-bottom: 0
}

@media only screen and (max-width: 1023px) {
    .container__pipeline .pipeline__block .cell-row:last-child {
        margin-bottom: -1px;
    }
}

.container__pipeline .pipeline__block .cell-row .special-cell {
    display: none;
    padding: 20px 20px 25px;
}

.container__pipeline .pipeline__block .cell-row .special-cell u {
    text-decoration: none;
}

.container__pipeline .pipeline__block .cell-row .cell-description {
    width: 25%;
    padding: 10px 15px;
    text-align: center;

    &.gene-target {
        width: 15%;

        @media screen and (max-width: 1023px) {
            width: 100%;
            border-right: 0;

            &:first-of-type {
                border-top: 1px solid transparentize($color__primary-1, 0.5);
            }
        }
    }

    .arrow-cell {
        width: 25%;
    }

    .copy-wrap {
        width: 75%;
    }

    .approach-arrow {
        width: 20px;
    }

    @media screen and (max-width: 1023px) {
        .arrow-cell, .copy-wrap {
            width: 50%;
        }

        .copy-wrap {
            text-align: left;
        }
    }
}

.container__pipeline .pipeline__block .cell {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid transparentize($color__primary-1, 0.5);
    border-right: 1px solid transparentize($color__primary-1, 0.5);

    &:last-of-type {
        border-right: 0;
    }

    p {
        margin: 0;

        .mobile-label {
            display: none;
        }
    }

    @media screen and (max-width: 1023px) {
        justify-content: left;

        p {
            .mobile-label {
                display: inline;
            }
        }
    }
}

@media only screen and (max-width: 1023px) {
    .container__pipeline .pipeline__block .cell-row .cell-description {
        width: 100%;
        //border-bottom: 0;
    }
}

.container__pipeline .pipeline__block .cell-row .cell-image {
    width: 14%;
    display: flex;
    align-items: center;
    flex-flow: column;
    justify-content: space-evenly;
    padding: 0 10px;
}

.container__pipeline .pipeline__block .cell-row .cell-image .mobile__text {
    display: none;
    font-size: 14px
}

@media only screen and (min-width: 320px) {
    .container__pipeline .pipeline__block .cell-row .cell-image .mobile__text {
        font-size:calc(14px + (800vw - 2560px)/320)
    }
}

@media only screen and (min-width: 640px) {
    .container__pipeline .pipeline__block .cell-row .cell-image .mobile__text {
        font-size:22px
    }
}

@media only screen and (min-width: 641px) {
    .container__pipeline .pipeline__block .cell-row .cell-image .mobile__text {
        font-size:16px
    }
}

@media only screen and (max-width: 640px) {
    .container__pipeline .pipeline__block .cell-row .cell-image .mobile__text {
        margin-left:22px
    }
}

.container__pipeline .pipeline__block .cell-row .cell-image img {
    width: 100%;
    -o-object-fit: scale-down;
    object-fit: scale-down
}

@media only screen and (max-width: 1023px) {
    .container__pipeline .pipeline__block .cell-row .cell-image {
        width:100%;
        justify-content: flex-start;
        flex-flow: row;
        padding: 10px
    }

    .container__pipeline .pipeline__block .cell-row .cell-image img {
        margin-right: 25px;
        width: auto
    }

    .container__pipeline .pipeline__block .cell-row .cell-image .mobile__text {
        display: block;
        margin-right: 20px
    }
}

@media only screen and (max-width: 479px) {
    .container__pipeline .pipeline__block .cell-row .cell-image img {
        max-width:80px
    }
}

@media only screen and (max-width: 399px) {
    .container__pipeline .pipeline__block .cell-row .cell-image img {
        max-width:80px;
        margin-right: 10px
    }
}

@media only screen and (max-width: 359px) {
    .container__pipeline .pipeline__block .cell-row .cell-image img {
        max-width:60px;
        margin-right: 10px
    }
}

.container__pipeline .pipeline__block .cell-row .cell-empty-row-wrapper {
    position: relative;
    display: inline-flex;
    flex-grow: 1;

    @media screen and (max-width: 767px) {
        //background-color: transparentize($color__primary-1, 0.3);
    }
}

@media only screen and (max-width: 1023px) {
    .container__pipeline .pipeline__block .cell-row .cell-empty-row-wrapper {
        margin: -1px -1px 1px 0;
        width: 100%;
    }

    .container__pipeline .pipeline__block .cell-row:first-of-type .cell-empty-row-wrapper {
        border-top: 1px solid transparentize($color__primary-1, 0.5);
    }
}

.container__pipeline .pipeline__block .cell-row .cell-empty-row-wrapper .arrow-percentage {
    position: absolute;
    height: 55px;
    width: 55%;
    max-width: 0;
    top: calc(50% - 28px);
    left: 0;

    @media screen and (max-width: 1023px) {
        height: 35px;
        top: calc(50% - 15px);
    }
}

.container__pipeline .pipeline__block .cell-row .cell-empty-row-wrapper .arrow-percentage.animate-arrow {
    animation: arrow-width .5s linear;
    animation-delay: .5s;
    animation-fill-mode: forwards;
    left: -14px;
}

.container__pipeline .pipeline__block .cell-row .cell-empty-row-wrapper .arrow-percentage:before {
    border-top: 27px solid transparent;
    border-bottom: 27px solid transparent;
    content: "";
    position: absolute;
    top: 0;
    left: 100%;

    @media screen and (max-width: 1023px) {
        border-top-width: 18px;
        border-bottom-width: 17px;
    }
}

.container__pipeline .pipeline__block .cell-row .cell-empty-row-wrapper .cell-empty-col {
    width: 25%;
    min-height: 60px;
    border-right: 1px solid transparentize($color__primary-1, 0.5);

    &:first-child {
        border-left: 0;
    }
    &:last-of-type {
        border-right: 0;
    }

    @media screen and (max-width: 1023px) {
        .container__pipeline .pipeline__block .cell-row .cell-empty-row-wrapper .cell-empty-col {
            border: 0;
        }
    }
}

.tissue-indications-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

@media only screen and (min-width: 1024px) {
    .container__pipeline .pipeline__block .cell-row .cell-empty-row-wrapper .cell-empty-col {
        min-height:90px
    }
}

@media only screen and (max-width: 1169px) {
    .site-curevacDe .pipeline__block .cell-row-header .dummy-block-text {
        font-size:12px
    }
}

@media only screen and (max-width: 991px) {
    .site-curevacDe .pipeline__block .cell-row-header .dummy-block-text {
        font-size:14px
    }
}

@media only screen and (min-width: 992px) {
    .site-curevacDe .pipeline__block .cell-table .block__category .text {
        padding:0 10px 0 5px
    }
}
