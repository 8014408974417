.logo-grid {
  background: white;
  .logos {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding-block: 2rem;
    .single-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-block: 1rem;
    }
  }


}
