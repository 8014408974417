.section.contact-form {
    padding: 3.125rem 0;

    &.bg-img {
        @include bg-props();
        color: white;
        position: relative;

        &:before, &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
            opacity: 0.75;
            
        }

        &:before {
            background-color: $color__primary-2;
            z-index: 0;
            mix-blend-mode: multiply;
        }

        &:after {
            background-color: $color__primary-1;
            z-index: 1;
        }

        .container, .container-fluid {
            position: relative;
            z-index: 3;
        }
    }

    .copy {
        @include font-size(1.25);
    }

    @media screen and (max-width: 767px) {
        text-align: center;
        
        .btn {
            margin-bottom: 2em;
        }
    }
}