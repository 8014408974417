.section.pullout-video {
    text-align: center;

    p {
        font-size: 1.5rem;
    }

    .video-wrap {
        position: relative;

        a.video-link {
            svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 14.375rem;
                transition: all .2s ease;
                opacity: 0.7;
                z-index: 2;
    
                @media screen and (max-width: 767px) {
                    width: 5.5rem;
                }
            }
            &:hover {
                svg {
                    opacity: 1;
                }
            }
        }
    }
}