// Genevant branding
// Primary
$color__primary-1: #0d3e46;
$color__primary-2: #1fbce1;

// Secondary
$color__secondary-1: #53becb;
$color__secondary-2: #70c6cd;
$color__secondary-3: #97c9ec;
$color__secondary-4: #cde9f3;
$color__secondary-5: #ea474a;
$color__secondary-6: #f2bb18;
$color__secondary-7: #f18c20;
$color__secondary-8: #6cc4be;
$color__secondary-9: #b668a7;
$color__secondary-10: #9c2483;

// _s default
$color__background-body: #fff;
$color__background-screen: #f1f1f1;
$color__background-hr: #ccc;
$color__background-button: transparent;
$color__background-pre: #eee;
$color__background-ins: #fff9c0;

$color__text-screen: #21759b;
$color__text-input: #666;
$color__text-input-focus: #111;
$color__link: royalblue;
$color__link-visited: purple;
$color__link-hover: $color__secondary-9;
$color__text-main: $color__primary-1; // #404040

$color__border-button: $color__primary-1;
$color__border-button-hover: $color__secondary-6;
$color__border-button-focus: $color__secondary-6;
$color__border-input: $color__primary-2;
$color__border-abbr: $color__primary-1;
