.intro-content {
    @include font-size(1.25);
    text-align: center;

    @media screen and (max-width: 767px) {
        @include font-size(1.2);
        //margin: 3.088rem 0;
    }
}

.leadership-item {
    position: relative;

    a {
        img {
            width: 100%;
            filter: grayscale(100%);
            position: relative;
            z-index: 0;
            transition: all .2s ease;
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
            background-color: $color__secondary-3;
            opacity: 0.8;
            mix-blend-mode: color;
            z-index: 1;
            transition: all .2s ease;
        }

        &:hover, &:active, &:focus {
            img {
                filter: grayscale(0%);
            }

            &:before {
                background-color: transparent;
            }
        }
    }
}

.page-template-leadership {
    .fancybox-content {
        padding: 0;
    }

    .leadership-item {
        @include font-size(1.25);
        margin: 2.25em 0;
    }

    .leadership-content {

        h2 {
            margin-bottom: 0;
        }

        .job-title {
            @include font-size(1.625);
            font-weight: 300;
            margin: 0 0 1.385em 0;
        }
    }

    .fancybox-button--close {
        display: none;
    }

    .fancybox-custom-button {
        position: absolute;
        top: 0;
        color: $color__primary-1;
        border: none;

        &:hover, &:active, &:focus {
            background: none !important;
            border: none;
        }

        &:hover {
            cursor: pointer;
        }

        @media screen and (max-width: 767px) {
            left: 0;
        }
    }

    .order-md-0 {
        .fancybox-custom-button {
            left: 0;
        }
    }
}