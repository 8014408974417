.section, .intro-content {
    padding: 4.75em 0;
}

.intro-content {
    padding-bottom: 2.75rem;
}

article {
    &:after {
        margin: 4.75rem 0;
    }
}

@import "pullout-paragraph";
@import "50-50";
@import "news-feed";
@import "usp";
@import "icon-row";
@import "leadership";
@import "contact-form-row";
@import "locations";
@import "pullout-video";
@import "publications";
@import "jobs";
@import "icon-grid";
