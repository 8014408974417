blockquote {
    margin: 3rem 0 0;

    q {
        &:before, &:after {
            @include font-size(16.786);
            font-weight: 500;
            position: absolute;
            line-height: 0;

            @media screen and (max-width: 1440px) {
                @include font-size(10.786);
            }
        }

        &:after {
            right: 0;
            bottom: 0;

            @media screen and (max-width: 1200px) {
                bottom: -10%;
            }

            @media screen and (max-width: 767px) {
                bottom: 20%; left: 0; right: 0;
            }
        }

        &:before {
            @media screen and (max-width: 767px) {
                top: 0; left: 0; right: 0;
            }
        }
    }

    cite {
        font-style: normal;

        @media screen and (max-width: 767px) {
            display: block;
            margin-top: 4em;
        }
    }
}

.image-left {
    .quote-block {
        padding: 8.125em 0 !important;

        q {
            &:before {
                right: 0;
            }

            &:after {
                right: auto;
                left: 0;

                @media screen and (max-width: 767px) {
                    right: 0;
                }
            }
        }
    }
}