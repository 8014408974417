.section.news-feed {
    text-align: center;

    .lead-copy {
        @include font-size(1.25);
        max-width: 1200px;
        margin: auto;
    }

    .date {
        font-weight: 600;
    }

    p.the-permalink {
        @include font-size(1.551);
        color: $color__primary-1;
        font-weight: 600;
    }

    .news-item {
        position: relative;
        display: block;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 2em 2.5em 2em;
        margin-top: 1.5em;
        align-items: center;
        height: 100%;
        margin: auto;
        border-bottom: 15px solid #1fbce1;

        a {
            color: $color__primary-1;
            
            &:before, &:after {
                content: '';
                display: block;
                position: absolute;
                top: 0; right: 0; bottom: 0; left: 0;
                background-color: transparentize(white, .25);
                z-index: 0;
                transition: all .2s ease;
            }
    
            &:after {
                background-color: #f9f9f9;
            }

            &:hover, &:active, &:focus {
                text-decoration: none;
    
                &:after {
                    background-color: transparentize($color__primary-2, .60);
                }
            }
        }

        p {
            margin-bottom: 0;
        }

        .copy-wrap {
            position: relative;
            z-index: 1;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-weight: 300;

            h3 {
                font-weight: 300;
            }
        }

        @media screen and (max-width: 1440px) {
            padding: 4em 2.5em 1em;
        }
    }

    a.btn {
        margin: auto;
        margin-top: 2em;
    }
}