.section.split-50-50 {
    position: relative;
    color: white;
    @include font-size(1.25);
    padding: 0;

    .bg-img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        object-fit: cover;
        object-position: center center;
        z-index: -1;
    }

    h2, h3 {
        margin-bottom: 0.5em;
    }

    .copy-wrap {
        position: relative;
        z-index: 3;
        padding: 6.125em 0;

        &:before, &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
            opacity: 0.35;
            z-index: -1;
        }

        &:before {
            background-color: $color__primary-2;
        }

        &:after {
            background-color: $color__primary-1;
        }

        &.quote-block, &.darken-bg {
            &:before, &:after {
                opacity: 0.55;
            }
        }

        &.double-img {
            &:before, &:after {
                display: none;
            }
        }

        @media screen and (max-width: 767px) {
            padding: 4.75em 0;
        }
    }

    .img-wrap.double-img {
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
            opacity: 0.75;
            z-index: 0;
            background-color: white;
        }

        img.img-5050 {
            object-fit: contain;
        }
    }

    .img-5050 {
        object-fit: cover;
        object-position: center center;
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        width: 100%;
        height: 100%;

        @media screen and (max-width: 767px) {
            position: relative;
        }
    }

    .video-link {
        width: 100%;
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        
        &:before, &:after {
            content: '';
            display: block;
            opacity: 0.35;
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
            z-index: 1;
        }

        &:before {
            background-color: $color__primary-1;
        }

        &:after {
            background-color: $color__primary-2;
            mix-blend-mode: multiply;
        }

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 14.375rem;
            transition: all .2s ease;
            opacity: 0.7;
            z-index: 2;

            @media screen and (max-width: 767px) {
                width: 5.5rem;
            }
        }

        &:hover {
            svg {
                opacity: 1;
            }
        }
    }

    @media screen and (max-width: 767px) {
        text-align: center;
    }

    &.image-left {
        text-align: right;

        @media screen and (max-width: 767px) {
            text-align: center;
        }
    }

    &.no-bg {
        .img-5050 {
            object-fit: initial;
            object-position: initial;
            position: relative;
            width: auto;
            height: auto;
            margin: auto;
        }

        .copy-wrap {
            color: $color__primary-1;
    
            &:before, &:after {
                display: none;
            }
        }
    }
}