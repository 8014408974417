.gallery {
	margin-bottom: 1.5em;
}

.gallery-item {
	display: inline-block;
	text-align: center;
	vertical-align: top;
	width: 100%;

	// Loops to enumerate the classes for gallery columns.
	@for $i from 2 through 9 {
		.gallery-columns-#{$i} & {
			max-width: map-get( $columns, $i );
		}
	}
}

.gallery-caption {
	display: block;
}
