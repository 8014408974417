.sticky {
	display: block;
}

.post,
.page {
	margin: 0;
}

#primary {
	position: relative;
}

.page-bg {
	position: relative;

	&:before {
		content: '';
		display: block;
		background-image: url('./img/genevant-bg.png');
		@include bg-props(center center, no-repeat, cover);
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0; right: 0; bottom: 0; left: 0;
		opacity: 0.15;
		z-index: -999;
	}
}

.updated:not(.published) {
	display: none;
}

.page-content,
.entry-content,
.entry-summary {
	margin: 0 0;
}

.page-links {
	clear: both;
	margin: 0 0 1.5em;
}

.blog {

	.news-articles {
		padding: 4.75rem 0 0;
  }
  
  .entry-title {
    a, a:visited {
      color: $color__primary-1;

      &:hover, &:focus {
        color: $color__secondary-10;
      }
    }
  }

  .posted-on {
    a, a:visited {
      color: $color__secondary-10;
      text-decoration: underline;

      &:hover, &:focus {
        color: $color__primary-1;
        text-decoration: none;
      }
    }
  }

	article {
		position: relative;
    width: 100%;
    @include font-size(1.3);

		&:after {
			content: '';
			display: block;
			width: 100%;
			height: 1px;
      background-color: $color__primary-1;
      margin: 3.75rem 0;
		}

		&:last-of-type {
			&:after {
				height: 0;
				margin: 2.375rem 0;
			}
		}

	}
}

.posts-wrap {
  width: 100%;
}

.single {
	article {
		@include font-size(1.3);
		padding: 4.75rem 0;

		ul {
			margin-left: 0;
		}
	}

	.entry-header {
		@include font-size(1.2);
		padding-bottom: 2em;
		font-style: italic;
	}
}