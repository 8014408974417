$lightBlue: #70c4cc;
$darkBlue: #23bade;
$pink: #b768a6;
$purple: #9c2483;
$animTime: 2.5s;
$animType: cubic-bezier(0.77, 0, 0.175, 1);

.rna-strand {
    display: inline-block;
    width: 50px;
    margin: 0;
    transition: all .4s ease-in-out;
    margin: 0 3rem;

    @media screen and (max-width: 767px) {
        width: 40px;
        margin: 0 auto;
    }

    .light-blue-strand,
    .line-shadow,
    .dark-blue-strand,
    .purple-line,
    .pink-line {
        /*fill: transparent !important;
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
        stroke-width: 1px;*/
    }

    .pink-line,
    .purple-line {
        /*stroke-dasharray: 200;
        stroke-dashoffset: 200;*/
    }

    &.show {
        margin: 0 3rem;

        @media screen and (max-width: 767px) {
            margin: 0 auto;
        }

        .light-blue-strand {
            animation: strokeLightBlue $animTime $animType;
            animation-fill-mode: forwards;
        }

        .dark-blue-strand {
            animation: strokeDarkBlue $animTime $animType;
            animation-fill-mode: forwards;
        }

        .pink-line {
            animation: strokePink $animTime $animType;
            animation-fill-mode: forwards;
        }

        .purple-line {
            animation: strokePurple $animTime $animType;
            animation-fill-mode: forwards;
        }

        .line-shadow {
            animation: shadow $animTime $animType;
            animation-fill-mode: forwards;
        }
    }
}

.light-blue-strand,
.line-shadow { // Light blue
    stroke: $lightBlue;
}

.dark-blue-strand { // Dark blue
    stroke: $darkBlue;
}

.pink-line { // Pink
    stroke: $pink;
}

.purple-line { // Purple
    stroke: $purple;
}

@keyframes strokeLightBlue {
    0% {
        stroke-dashoffset: 1000;
        fill: transparent;
    }

    25% {
        stroke-dashoffset: 0;
        fill: transparent;
    }

    35% {
        fill: $lightBlue;
    }

    100% {
        stroke-dashoffset: 0;
        fill: $lightBlue
    }
}

@keyframes strokeDarkBlue {
    0% {
        stroke-dashoffset: 1000;
        fill: transparent;
    }

    25% {
        stroke-dashoffset: 0;
        fill: transparent;
    }

    35% {
        fill: $darkBlue;
    }

    100% {
        stroke-dashoffset: 0;
        fill: $darkBlue;
    }
}

@keyframes strokePink {
    0% {
        stroke-dashoffset: 200;
        fill: transparent;
    }

    20% {
        stroke-dashoffset: 200;
        fill: transparent;
    }

    40% {
        stroke-dashoffset: 200;
        fill: transparent;
    }

    65% {
        stroke-dashoffset: 0;
        fill: transparent;
    }

    75% {
        fill: $pink;
    }

    100% {
        stroke-dashoffset: 0;
        fill: $pink;
    }
}

@keyframes strokePurple {
    0% {
        stroke-dashoffset: 200;
        fill: transparent;
    }

    20% {
        stroke-dashoffset: 200;
        fill: transparent;
    }

    40% {
        stroke-dashoffset: 200;
        fill: transparent;
    }

    65% {
        stroke-dashoffset: 0;
        fill: transparent;
    }

    75% {
        fill: $purple;
    }

    100% {
        stroke-dashoffset: 0;
        fill: $purple;
    }
}

@keyframes shadow {
    0% {
        stroke-dashoffset: 1000;
        fill: transparent;
    }

    65% {
        stroke-dashoffset: 1000;
        fill: transparent;
    }

    75% {
        stroke-dashoffset: 0;
        fill: $lightBlue
    }

    100% {
        stroke-dashoffset: 0;
        fill: $lightBlue
    }
}