header {
    &#masthead {
        padding: 1.875rem 0;
        position: fixed;
        top: 0; right: 0; left: 0;
        z-index: 9999;
        background-color: transparent;
        transition: all .2s ease;

        .logo {
          max-width: 160px;
        }

        h1 {
            margin: 0;
            padding: 0;
            line-height: 0;
            font-size: 0;
            position: relative;
            z-index: 999;
        }

        &.white-bg {
            background-color: transparentize(white, 0.05);
        }

        .container {
            @media screen and (max-width: 992px) {
                width: 100%;
                max-width: 100%;
            }
        }
    }
}