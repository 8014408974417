.section.publications {
  background: #ffffff;

  .container {
    h3 {
      margin-bottom: 40px;
    }
  }
  .wrapper {
    margin-bottom: 20px;

    p {
      margin: 0px;
      font-size: 20px;
      line-height: 30px;
    }

    .title {
      font-weight: 600;
    }

    a {
      color: #11aed8;
      text-decoration: underline;

      p {
        display: inline;
      }
    }
  }
}
