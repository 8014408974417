.main-navigation {
  clear: both;
  display: block;
  float: left;
  width: 100%;
  font-size: 16px;

  ul {
    //display: none;
    list-style: none;
    margin: 0;
    padding-left: 0;

    ul {
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
      float: left;
      position: absolute;
      top: 100%;
      left: -999em;
      z-index: 99999;

      ul {
        left: -999em;
        top: 0;
      }

      li {
        &:hover > ul,
        &.focus > ul {
          left: 100%;
        }
      }

      a {
        width: 200px;
      }

      :hover > a,
      .focus > a {
      }

      a:hover,
      a.focus {
      }
    }

    li:hover > ul,
    li.focus > ul {
      left: auto;
    }
  }

  li {
    float: left;
    position: relative;

    &:hover > a,
    &.focus > a {
    }
  }

  a {
    display: block;
    text-decoration: none;
  }

  .current_page_item > a,
  .current-menu-item > a,
  .current_page_ancestor > a,
  .current-menu-ancestor > a {
  }

  button.menu-toggle {
    .burger-line {
      &.top,
      &.bottom {
        transition: all 0.2s ease;
      }
    }
  }

  &.toggled {
    button.menu-toggle {
      .burger-line {
        &.top {
          transform-origin: 30% 30%;
          transform: rotateZ(90deg);
        }

        &.bottom {
          transform-origin: 74% 70%;
          transform: rotateZ(-90deg);
        }
      }
    }

    @media screen and (max-width: 767px) {
      background-color: white;
    }
  }

  @media screen and (max-width: 767px) {
    ul {
      @include font-size(1.171);
      line-height: 2;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: url("./img/nav-bg.png");
      @include bg-props();
      opacity: 0.05;
    }
  }
}

/* Small menu. */
.menu-toggle,
.main-navigation.toggled ul {
  display: block;
}

@media screen and (min-width: 768px) {
  .menu-toggle {
    display: none;
  }
  .main-navigation ul {
    display: flex;
    justify-content: space-evenly;
  }
}

.comment-navigation,
.posts-navigation,
.post-navigation {
  width: 100%;

  .site-main & {
    margin: 0 0 1.5em;
    overflow: hidden;
  }

  .nav-previous {
    float: left;
    width: 50%;
  }

  .nav-next {
    float: right;
    text-align: right;
    width: 50%;
  }

  .nav-links {
    a {
      display: inline-block;
    }
  }
}

#site-navigation {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  .menu-primary-navigation-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    li {
      a {
        color: $color__primary-1;
        font-weight: 400;

        &:hover,
        &:focus,
        &:active {
          color: $color__secondary-9;
          text-decoration: underline;
        }
      }

      &.current_page_item,
      &.current_page_parent {
        a {
          color: $color__secondary-9;
          text-decoration: underline;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 100%;
    background-color: white;
    transition: all 0.2s ease-in-out;

    ul {
      display: flex;
      flex-direction: column;
      text-align: center;
    }

    &.toggled {
      left: 0;
    }
  }
}

button.menu-toggle {
  position: fixed;
  top: 2em;
  right: 1.5em;
  outline: 0;
  border: 0;
  padding: 0;
  margin: auto;
  width: 36px;
  height: 36px;

  &:hover,
  &:active,
  &:focus {
    border: 0;
    background: none !important;
  }
}

.menu-social-media-container {
  #social-media {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: right;

    li {
      display: inline-block;
      margin: 0 20px;

      &.linkedin,
      &.twitter {
        width: 40px;
        height: 40px;
        a {
          &:before {
            display: block;
            width: 40px;
            height: 40px;
          }
        }
      }

      &.linkedin {
        a {
          &:before {
            content: url("./img/icon-social-linkedin.svg");
          }
        }
      }

      &.twitter {
        a {
          &:before {
            content: url("./img/icon-social-twitter.svg");
          }
        }
      }

      a {
        font: 0/0 a;
        color: transparent;
        text-shadow: none;
        background-color: transparent;
        border: 0;
      }
    }

    @media screen and (max-width: 992px) {
      text-align: center;
      margin-top: 1em;
    }
  }
}

#more_posts {
  text-align: center;
  margin: 3em 0;
}
