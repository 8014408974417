footer.site-footer {
    text-align: center;
    background-color: $color__secondary-4;
    padding: 2.25em 0 1.25em;

    .logo-wrap {
        max-width: 160px;

        @media screen and (max-width: 992px) {
            margin: 1em auto;
        }
    }

    .copyright {
        text-transform: uppercase;
        margin-top: 0.7em;

        a {
            text-transform: none;
            color: $color__primary-1;
            text-decoration: underline;

            @media screen and (max-width: 1200px) {
                display: block;
            }
        }

        @media screen and (max-width: 1200px) {
            margin: 0;
        }

        @media screen and (max-width: 992px) {
            margin: 1em 0;
        }
    }

    @media screen and (max-width: 992px) {
        padding-bottom: 2.25em;
    }
}