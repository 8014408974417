.section.locations {

    h2 {
        margin-bottom: 0.5em;
    }

    h3 {
        @include font-size(2.25);
        margin-bottom: 0;
        font-weight: 400;

        @media screen and (max-width: 1024px) {
            @include font-size(1.5);
        }
    }

    address {
        @include font-size(2.188);
        line-height: 1.1;

        @media screen and (max-width: 1024px) {
            @include font-size(1.513);
        }
    }

    a {
        @include font-size(2.188);
        font-weight: 400;
        color: $color__primary-1;
        display: inline-block;
        margin: 1.125rem 0;
        
        @media screen and (max-width: 1024px) {
            @include font-size(1.513);
        }
    }

    .map {
        width: 100%;
        height: 425px;
        margin: 5.625rem 0;

        a {
            @include font-size(0.75);
            margin: 0;
        }

        @media screen and (max-width: 1024px) {
            margin: 1.625rem 0;
        }
    }
}