.section.pullout-paragraph {
    .greenhouse-job-board {
        .all_jobs {
            margin-top: -6.5em;

            h2 {
                @include font-size(1.75);
            }

            .job {
                padding: 2em 15px;
                background-color: white;
                box-shadow: -4px 4px 0px 0px rgba(13, 62, 70, 0.25);
                margin-bottom: 2em;

                .job_description {
                    text-align: left;
                }

                a {
                    font-weight: 600;

                    &:visited {
                        color: $color__primary-1;
                    }
                }
            }

            #grnhse_app {
                display: none;
            }

            form {
                text-align: left;
                padding-top: 2.5rem;

                h2 {
                    display: none;
                }

                .field_wrap {
                    width: 50%;
                    display: inline-block;
                    padding: 0 15px;

                    input {
                        width: 100%;
                    }

                    &.field_submit {
                        width: 100%;
                        text-align: center;
                        margin-top: 1em;

                        input {
                            width: 50%;
                            margin: auto;
                        }
                    }
                }
            }
        }
    }
}