h1, h2, h3, h4, h5, h6 {
	clear: both;
	width: 100%;
	font-weight: 300;
}

h1,h2 {
	@include font-size(3.13);
	margin-bottom: 0.5em;

	@media screen and (max-width: 1024px) {
		@include font-size(2.313);
	}
}
