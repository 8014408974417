#hero {
    text-align: center;
    position: relative;
    overflow-y: hidden;
    margin: 0;
    padding: 10em 0;

    .hero-content {
        position: relative;
        z-index: 2;
        max-width: 890px;
        margin: auto;

        h1, h2 {
            font-weight: 300;
            width: 100%;
        }

        .home-title {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .hero-copy {
            @include font-size(1.8);
            line-height: 1;

            p {
                margin: 0;
            }

            @media screen and (max-width: 1024px) {
                @include font-size(1.5);
            }
        }

        .btn {
            margin-top: 1.5em;
        }
    }

    .background-image {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        opacity: 0.6;
        object-fit: cover;
        object-position: center center;

        &.hide-desktop {
            @media screen and (min-width: 991px) {
                display: none;
            }
        }
    }

    .background-video {
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        overflow: hidden;

        video {
            position: absolute;
            top: 50%;
            left: 50%;
            min-width: 100%;
            min-height: 100%;
            width: auto;
            height: auto;
            z-index: -100;
            transform: translateX(-50%) translateY(-50%);
        }

        @media screen and (max-width: 992px) {
            display: none;
        }
    }

    @media screen and (max-width: 767px) {
        padding: 8em 0;
    }

    @media screen and (max-width: 425px) {
        padding: 5em 0;
    }
}