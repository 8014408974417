.section.pullout-paragraph, .section.contact-pullout {
    text-align: center;
    @include font-size(1.25);

    @media screen and (max-width: 1024px) {
        padding: 4.75em 0;
    }

    .copy-wrap {
        z-index: 1;
    }

    &.mix-color-bg {
        position: relative;
        background-color: transparentize($color__primary-2, 0.65);
        color: white;

        &:before, &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
            z-index: 0;
        }

        &:before {
            background-color: transparentize($color__primary-1, 0.65);
        }

        &:after {
            background-color: transparentize(#0e94b3, 0.7);
        }
    }

    &.bg-img {
        @include bg-props();
        color: white;
    }

    ~ .logo-row {
        background-color: white;
        padding: 1.5rem 1rem;

        .logos {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;
          flex-wrap: wrap;
          max-width: 1300px;
          margin: auto;

          img {
            max-width: 20%;
            margin: auto;

            @media screen and (max-width: 991px) {
              max-width: 50%;
              margin: 0.5rem;
            }
          }
        }
      }
}

.section.contact-pullout {
    position: relative;

    h2 {
        margin-bottom: 0.1em;
    }

    p {
        @include font-size(1.575);
        line-height: 1.3;

        @media screen and (max-width: 767px) {
            @include font-size(1.375);
        }
    }

    &.keyline {
        &:before {
            content: '';
            display: block;
            height: 1px;
            width: 80%;
            background-color: $color__primary-1;
            position: absolute;
            top: 2rem;
            margin-left: auto;
            margin-right: auto;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

.blog .section.contact-pullout {
    width: 100%;
}

// .section.pullout-paragraph:first-of-type {
//     padding: 1em 0 1.5em;
// }