.section.icon-row, .section.image-row {
    text-align: center;

    .lead-copy {
        @include font-size(1.25);
        max-width: 1200px;
        margin: auto;
    }

    .icon-block {
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;

        @media screen and (max-width: 1024px) {
            margin: 1rem auto;
        }
    }

    .icon-caption {
        @include font-size(1.575);
        margin-top: 1rem;
        margin-bottom: 0.1em;
        line-height: 1;
        font-weight: 400;

        @media screen and (max-width: 1024px) {
            @include font-size(1.5);
        }
    }

    .icon-details {
        @include font-size(1.2);
        margin-top: 1em;

        small {
            @include font-size(0.9);
        }

        @media screen and (min-width: 991px) and (max-width: 1573px) {
            br {
                display: none;
            }
        }

        @media screen and (max-width: 440px) {
            br {
                display: none;
            }
        }
    }
}

.section.icon-row {

    &:first-of-type {
        margin-bottom: 0 !important;
    }

    &:last-of-type {
        margin-top: 0 !important;
    }
}

.section.image-row {

    .copy {
        margin: 1em 0 3em;
    }

    .image-block {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        @include bg-props();
        height: 100%;

        &.has-image:before {
            content: '';
            background-color: #fff;
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
            opacity: 0.7;
            z-index: 0;
        }

        .image-caption {
            z-index: 2;
            padding: 4em 1.5em;
            position: relative;
            top: 50%;
            transform: translateY(-50%);

            @media screen and (max-width: 1024px) {
                margin-bottom: 5rem;
                padding: 5em 1em;
            }
        }

        &.has-image {
            .image-caption {
                padding: 10em 1.5em;
            }
        }
    }

    p {
        @include font-size(1.25);
    }
}