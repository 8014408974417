i.icon {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;

    &.icon-search {
        &:before {
            content: '';
            display: inline-block;
            width: 20px;
            height: 20px;
            background-image: url('./img/icon-magni.svg');
            @include bg-props( center, no-repeat, contain );
        }
    }
}