input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
  color: $color__text-input;
  border: 1px solid $color__border-input;
  border-radius: 0;
  padding: 3px 15px;

  &:focus {
    color: $color__text-input-focus;
  }
}


select {
	border: 1px solid $color__border-input;
}

textarea {
	width: 100%;
}

input, textarea {
  font-weight: 300;
  @include font-size(1.341);
  padding: 0.85rem 1.25rem;

  &:-webkit-input-placeholder,
  &:-moz-placeholder,
  &:-ms-input-placeholder,
  &:-moz-placeholder,
  &::placeholder {
    color: $color__primary-1;
    font-weight: 300;
  }
}

input, textarea {
  @include font-size(1.641);
}