#header-search {
    padding: 1em 0;
    text-align: right;
    display: none;

    #searchform {
        display: flex;
        justify-content: flex-end;

        input[type="submit"] {
            margin-left: 15px;
        }
    }
}

.search-icon-wrap {
    text-align: right;
    
    #toggleSearch {
        float: right;
        margin-left: 15px;

        &:before {
            width: 15px;
            height: 37px;
        }

        @media screen and (max-width: 767px) {
            float: none;
            margin-left: 0;

            &:before {
                width: 25px;
                height: 31px;
            }
        }
    }

    @media screen and (max-width: 767px) {
        text-align: center;
    }
}