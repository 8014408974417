@import "buttons";

@import "fields";

@import "search";

.c-forms-form {
    &:focus {
        outline: 0;
    }

    .c-label {
        display: none;
    }
}

#contact-lightbox,
#contact-bd-lightbox {
    width: 40%;
    padding: 2.813rem;
    border-radius: 6px;

    @media screen and (max-width: 1024px) {
        width: 80%;
    }

    @media screen and (max-width: 425px) {
        width: 100%;
    }
}